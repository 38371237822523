import React from 'react';
import Label from "../atoms/Label";

const InputGroup = ({ name, label, type, placeholder, value, onChange, className }) => {
  return (
    <div className={className}>
      <Label htmlFor={name}>{label}</Label>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full p-2 border border-gray-300 rounded"
      />
    </div>
  );
};

export default InputGroup;