import Text from "../atoms/Text";

const FirstMessage = ({ className={} }) => {
  return (
    <div className={`${className}`}>
      <Text content="電脳の力で、" />
      <Text content="ブロックチェーンの可能性を追求する" className="hidden md:block" />

      <Text content="ブロックチェーンの可能性を" className="md:hidden" />
      <Text content="追求する" className="md:hidden" />
    </div>
  );
};

export default FirstMessage;
