const Button = ({ type, children }) => {
  return (
    <button
      type={type}
      className="w-full button-base text-[#0094FF] border border-[#0094FF] solid py-[10px] text-[20px] font-abel"
    >
      {children}
    </button>
  );
};

export default Button;
