import Text from "../atoms/Text";

const ProfileContent = ({ content, className='' }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <Text content={`${content}`} className='text-[14px]'/>
    </div>
  );
}

export default ProfileContent;
