import Text from "../atoms/Text";
import Image from "../atoms/Image";

const Partners = ({className=''}) => {
  return (
    <div className={`${className}`}>
      <div className="h-[500px] max-lg:hidden">
        <div className="relative w-[777px] h-[418px] mx-auto">
          <div className="absolute inset-0 shadow-2xl bg-white-opacity-50 backdrop-blur-100 flex items-center justify-center z-10"></div>
          <div className="absolute inset-0 shadow-2xl bg-white-opacity-50 backdrop-blur-100 transform translate-x-[15px] translate-y-[15px] z-20"></div>
          <div className="absolute inset-0 shadow-2xl bg-white-opacity-50 backdrop-blur-100 transform translate-x-[30px] translate-y-[30px] z-30"></div>
          <div className="absolute inset-0 shadow-2xl bg-white-opacity-50 backdrop-blur-100 transform translate translate-x-[45px] translate-y-[45px] z-40">
            <Text content='*OUR PARTNERS' className='text-[#0094FF] text-[30px] mt-[40px] mb-[20px] text-center font-abel'/>
            <Image src={process.env.PUBLIC_URL + '/partner_1.png'} className='w-[720px] h-[403px] mt-[-60px] mx-auto'/>
          </div>
        </div>
      </div>

      <div className="h-[350px] md:h-[500px] lg:hidden">
        <div className="w-[375px] min-target:w-[390px] md:w-[550px] mx-auto">
          <div className="relative w-[340px] min-target:w-[350px] md:w-[500px] h-[340px] min-target:h-[350px] md:h-[500px]">
            <div className="absolute inset-0 shadow-2xl bg-white-opacity-50 backdrop-blur-100 flex items-center justify-center z-10"></div>
            <div className="absolute inset-0 shadow-2xl bg-white-opacity-50 backdrop-blur-100 transform translate-x-[10px] translate-y-[10px] z-20"></div>
            <div className="absolute inset-0 shadow-2xl bg-white-opacity-50 backdrop-blur-100 transform translate-x-[20px] translate-y-[20px] z-30"></div>
            <div className="absolute inset-0 shadow-2xl bg-white-opacity-50 backdrop-blur-100 transform translate translate-x-[30px] translate-y-[30px] z-40">
              <Text content='*OUR PARTNERS' className='text-[#0094FF] text-[30px] mt-[20px] mb-[10px] md:mb-[20px] text-center font-abel'/>
              <div className="w-[340px] min-target:w-[350px] md:w-[500px] h-[275px] md:h-[400px]">
                <Image src={process.env.PUBLIC_URL + '/partner_2.png'} className='w-[180px] h-[256px] m-auto mt-[-10px] md:mt-[-30px]'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
