import Spline from '@splinetool/react-spline';
import RandomSquares from "../molecules/RandomSquares";

const FullScreen = () => {
  return (
    <div className='fixed'>
      <RandomSquares count={15} color='white' />
      <RandomSquares count={5} color='#0094FF' />
      <Spline className='fixed -z-1' scene="https://prod.spline.design/v670kf6kjomDiTnS/scene.splinecode" />
    </div>
  );
};

export default FullScreen;
