import React, { useState } from 'react';
import axios from 'axios';
import Text from "../atoms/Text";
import InputGroup from "../molecules/InputGroup";
import Button from "../atoms/Button";
import Label from "../atoms/Label";

const ContactForm = ({ className }) => {
  const [formData, setFormData] = useState({
    name: '',
    furigana: '',
    company: '',
    email: '',
    phone: '',
    inquiryReason: '',
    inquiryContent: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { name, furigana, company, email, phone, inquiryReason, inquiryContent } = formData;
    if (!name || !furigana || !company || !email || !phone || !inquiryReason || !inquiryContent) {
      alert('全てのフィールドを入力してください。');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      await axios.post('/api/send-email', formData);
      alert('メールが送信されました');
    } catch (error) {
      alert('メールの送信に失敗しました');
    }
  };

  return (
    <div className={`${className}`}>
      <div className='relative w-[330px] md:w-[500px] lg:w-[800px] h-[1054px] lg:h-[1186px] shadow-2xl bg-white-opacity-30 backdrop-blur-100 mx-auto flex flex-col justify-center'>
        <form className="w-[270px] md:w-[420px] lg:w-[500px] mx-auto py-4 lg:p-4" onSubmit={handleSubmit}>
          <Text content='CONTACT' className='text-[#0094FF] text-[16px] font-abel text-center mb-[10px] lg:mb-6'/>
          <Text content='お問い合わせ' className='text-[16px] font-bold text-center mb-[10px] lg:mb-6'/>
          <div className='w-[270px] md:w-[370px] lg:w-[398px] h-[66px] md:h-[40px] lg:h-[50px] mb-[51px] mx-auto'>
            <Text content='下記にお問い合わせ内容をご入力の上、フォームを送信してください。どなたでも気軽にご相談お待ちしております。' className='text-[12px] lg:text-[14px]'/>
          </div>
          
          <InputGroup name="name" label="お名前" type="text" placeholder="山田太郎" value={formData.name} onChange={handleChange} className='mb-4 text-[12px]' />
          <InputGroup name="furigana" label="お名前（フリガナ）" type="text" placeholder="ヤマダタロウ" value={formData.furigana} onChange={handleChange} className='mb-4 text-[12px]' />
          <InputGroup name="company" label="会社名" type="text" placeholder="株式会社 電脳組合" value={formData.company} onChange={handleChange} className='mb-4 text-[12px]' />
          <InputGroup name="email" label="メールアドレス" type="email" placeholder="info@dennoguild.com" value={formData.email} onChange={handleChange} className='mb-4 text-[12px]' />
          <InputGroup name="phone" label="電話番号" type="tel" placeholder="03-1234-5678" value={formData.phone} onChange={handleChange} className='mb-4 text-[12px]' />
          <InputGroup name="inquiryReason" label="お問い合わせいただいたきっかけ" type="text" placeholder="" value={formData.inquiryReason} onChange={handleChange} className='mb-4 text-[12px]' />
          <div className="mb-[51px]">
            <Label htmlFor="inquiryContent">お問い合わせ内容</Label>
            <textarea
              name="inquiryContent"
              placeholder="お問い合わせ内容をご記入ください。"
              className="w-full p-2 border border-gray-300 rounded"
              rows="10"
              value={formData.inquiryContent}
              onChange={handleChange}
            ></textarea>
          </div>
          <Button type="submit">CONTACT</Button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
