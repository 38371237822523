import ServiceArticle from "../molecules/ServiceArticle";

const Services = ({className=''}) => {
  return (
    <div id='services' className={`relative overflow-hidden ${className}`}>
      <div className="md:flex md:overflow-x-scroll py-10 md:space-x-4 md:ml-[20%] md:pr-10">
        <ServiceArticle className="mb-[35px] md:mb-0 mx-auto" title='Web2＋Web3マーケティング' content='プロジェクトの潜在的な魅力を最大限に引き出すためのWeb2およびWeb3マーケティング戦略を提案します。3つの具体的なプランを通じて、従来のWeb2ユーザーと新しいWeb3ユーザーの双方にアプローチし、効率的かつ効果的なプロモーションを実現します。' number='01' src={process.env.PUBLIC_URL + '/マーケティング.png'}/>
        <ServiceArticle className="mb-[35px] md:mb-0 mx-auto" title='コンサルティング' content='ブロックチェーン業界に精通した専門のプロフェッショナルチームが、プロジェクトの企画、トケノミクスの設計、システム開発の支援、そして戦略的アドバイスを提供します。クライアントのニーズに合わせて、最適な成長戦略を共に考え、プロジェクトの成功をサポートします。' number='02' src={process.env.PUBLIC_URL + '/コンサルティング.png'}/>
        <ServiceArticle className="mb-[35px] md:mb-0 mx-auto" title='システム・アプリ開発' content='プロジェクトの進化に伴う技術的な要件を満たすため、システムおよびアプリケーションの開発を全面的に支援します。スマートコントラクトや分散型アプリケーションの開発、ウォレット開発など、既存システムのアップデートまで幅広い技術サポートを提供します。' number='03' src={process.env.PUBLIC_URL + '/システムアプリ開発.png'}/>
        <ServiceArticle className="mb-[35px] md:mb-0 mx-auto" title='トークンエコノミクス' content='プロジェクトの基盤となるトークンエコノミクスの設計と最適化をサポートします。プロジェクトの目標に合わせたトークンの設計、発行、配布方法を検討し、持続可能な経済圏の構築をお手伝いします。トークンのユースケースやインセンティブ構造を明確にすることで、プロジェクトの価値を最大化します。' number='04' src={process.env.PUBLIC_URL + '/トークンエコノミクス.png'}/>
        <ServiceArticle className="mb-[35px] md:mb-0 mx-auto" title='プロジェクト企画立案' content='アイデアの段階から実現に向けた具体的なステップまで、プロジェクトの企画立案をサポートします。市場調査や競合分析を行い、最適な戦略を立てることで、プロジェクトの成功確率を高めます。実行可能なロードマップを作成し、進捗管理を行うことで、計画の遅延やリスクを最小限に抑えます。' number='05' src={process.env.PUBLIC_URL + '/企画立案.png'}/>
        <ServiceArticle className="mb-[35px] md:mb-0 mx-auto" title='新独自サービス（Coming soon）' content='次世代のブロックチェーン技術を活用した新たな独自サービスを準備中です。業界のニーズに応える革新的なソリューションを提供し、ユーザー体験の向上を目指します。詳細は近日公開予定ですので、ご期待ください。' number='06' src={process.env.PUBLIC_URL + '/新独自サービス.png'}/>
      </div>
    </div>
  );
}

export default Services;
