import Text from "../atoms/Text";

const ProfileLabel = ({ content }) => {
  return (
    <div className='w-[88px] flex flex-col'>
      <Text content={`${content}`} className='text-[14px] font-bold'/>
    </div>
  );
}

export default ProfileLabel;
