import Image from "../atoms/Image";

const ContactFormBackground2 = ({ className={} }) => {
  return (
    <>
      <div className='relative h-[0px]'>
        <Image src={process.env.PUBLIC_URL + '/contact_3.png'} className='absolute 
        -top-[400px] md:-top-[500px] lg:-top-[550px] 
        right-[130px] md:right-[230px] lg:right-[230px] 
        z-0 w-[13px] h-[327px] mx-auto lg:relative'/>
      </div>

      <div className='relative h-[0px]'>
        <Image src={process.env.PUBLIC_URL + '/contact_3.png'} className='absolute  
        -top-[500px] md:-top-[600px] lg:-top-[720px] 
        max-md:left-[150px] md:right-[160px] lg:right-[460px]
         z-0 w-[13px] h-[327px] mx-auto lg:relative'/>
      </div>

      <div className='relative h-[0px] max-lg:hidden'>
        <Image src={process.env.PUBLIC_URL + '/contact_12.png'} className='absolute  
        -top-[1500px] md:-top-[1500px]  lg:-top-[1500px] 
        left-[50px] md:left-[360px] lg:left-[360px] 
        z-0 w-[13px] h-[1264px] mx-auto lg:relative'/>
      </div>

      <div className='relative h-[0px] lg:hidden'>
        <Image src={process.env.PUBLIC_URL + '/contact_6.png'} className='absolute 
        -top-[1000px] md:-top-[1000px]
        right-[20px] md:right-[760px] 
        z-0 w-[13px] h-[643px] mx-auto lg:relative'/>
      </div>

      <div className='relative h-[0px]'>
        <Image src={process.env.PUBLIC_URL + '/contact_3.png'} className='absolute  
        -top-[600px] md:-top-[450px] lg:-top-[650px] 
        left-[10px] md:left-[260px] lg:left-[460px] 
        z-0 w-[13px] h-[327px] mx-auto lg:relative'/>
      </div>
    </>
  );
};

export default ContactFormBackground2;
