import Logo from "../atoms/Logo";
import MeatballMenu from "../atoms/MeatballMenu";

const Header = () => {
  return (
    <header className="fixed w-full h-[100px] flex justify-between items-end z-20">
      <Logo width='100%' height='100%' className="w-[54px] md:w-[68px] h-[54px] md:h-[68px] block ml-6 md:ml-8"/>
      {/* <MeatballMenu width='100%' height='100%' className={'mr-8 w-[68px] h-[68px] flex justify-center items-center'}/> */}
    </header>
  );
};

export default Header;
