import './App.css';
import Header from './components/organisms/Header';
import FullScreen from './components/organisms/FullScreen';
import FirstMessage from './components/molecules/FirstMessage';
import CompanyAbout from './components/molecules/CompanyAbout';
import CompanyConcept from './components/molecules/CompanyConcept';
import CompanyMessage from './components/molecules/CompanyMessage';
import CompanyImage from './components/molecules/CompanyImage';
import Text from './components/atoms/Text';
import Articles from './components/organisms/Articles';
import Services from './components/organisms/Services';
import Partners from './components/molecules/Partners';
import CompanyProfile from './components/organisms/CompanyProfile';
import ContactForm from './components/organisms/ContactForm';
import Image from './components/atoms/Image';
import ServicesBackground from './components/molecules/ServicesBackground';
import ContactFormBackground1 from './components/molecules/ContactFormBackground1';
import ContactFormBackground2 from './components/molecules/ContactFormBackground2';

// 記事データを定義します
const articles = [
  {
    date: '4/23',
    year: '2024',
    newsHref: '#',
    content: 'ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース'
  },
  {
    date: '4/23',
    year: '2024',
    newsHref: '#',
    content: 'ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行'
  },
  {
    date: '4/23',
    year: '2024',
    newsHref: '#',
    content: 'ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行ニュース最大３行'
  }
];


function App() {
  return (
    <>
      <Header />
      <div className='w-full h-full bg-[#E9EDF5] font-hiragino'>
        <FullScreen />
        <div className='relative'>
          <div className='max-target:w-[375px] w-[390px] md:w-[768px] lg:w-[1024px] h-lvh relative mx-auto pb-[80px]'>
            <FirstMessage className={'absolute left-[20px] md:left-[60px] lg:left-[80px] bottom-[100px] md:bottom-[80px] lg:bottom-[60px] text-[22px] md:text-[27px] lg:text-[32px] leading-loose'}/>
          </div>
          
          <div id='CompanyAbout' className='max-target:w-[375px] w-[390px] md:w-[675px] mx-auto pb-10'>
            <CompanyAbout className='mb-5 shadow-2xl bg-white-opacity-30 backdrop-blur-100'/>
            <CompanyConcept className='mb-5 ml-auto md:ml-32 -mt-10 shadow-2xl bg-white-opacity-30 backdrop-blur-100'/>
            <CompanyMessage className='mb-5 ml-[20px] md:ml-16 -mt-10 shadow-2xl bg-white-opacity-30 backdrop-blur-100'/>
            {/* <CompanyImage src={process.env.PUBLIC_URL + '/Rectangle40.png'} className='-mt-10' /> */}
          </div>

          <ServicesBackground />

          <Services className='pb-[80px] mt-[-200px] z-10'/>

          <div className='relative h-[368px] max-lg:mt-[-368px] max-lg:overflow-hidden'>
            <Image src={process.env.PUBLIC_URL + '/SERVICES_1.png'} className='absolute max-w-fit w-[1372px] h-[368px] lg:-top-[300px] overflow-hidden lg:relative'/>
          </div>

          {/* <Articles className='py-[80px] lg:mt-[-368px]' articles={articles}/> */}

          <Partners className='pb-[80px]'/>

          <CompanyProfile className='pb-[80px]'/>

          <ContactFormBackground1 />

          <ContactForm className='pb-[500px] z-10'/>

          <ContactFormBackground2 />

          <div className='pb-[30px]'>
            <Text content='DENNO-GUILD.INC' className='text-[14px] text-center opacity-20'/>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
