import Text from "../atoms/Text";
import ProfileLabel from "../molecules/ProfileLabel";
import ProfileContent from "../molecules/ProfileContent";

const CompanyProfile = ({ className='' }) => {
  return (
    <div className={`${className}`}>
      <div className="flex justify-center">
        <div className='w-full lg:w-[599px] h-[632px] lg:h-[546px] shadow-2xl bg-white-opacity-30 backdrop-blur-100 lg:ml-[400px] flex justify-center items-center'>
          <div className='w-full lg:w-[399px] h-[512px] lg:h-[346px] mx-auto'>
            <div className='w-[266px] md:w-[360px] lg:w-[399px] h-[20px] mb-[30px] lg:mb-[50px] max-lg:mx-auto'>
              <Text content='COMPANY PROFILE' className='text-[#0094FF] text-[16px] font-abel'/>
            </div>

            <div className='w-[266px] md:w-[360px] lg:w-[399px] h-[462px] lg:h-[276px] flex flex-col justify-between items-center max-lg:mx-auto'>
              <div className='w-[266px] md:w-[360px] lg:w-[399px] lg:flex lg:justify-between'>
                <ProfileLabel content='会社名'/>
                <ProfileContent className="w-[280px]" content='株式会社 電脳組合 / DennoGuild INC.'/>
              </div>

              <div className='w-[266px] md:w-[360px] lg:w-[399px] lg:flex lg:justify-between'>
                <ProfileLabel content='代表'/>
                <ProfileContent className="w-[280px]" content='堀口 啓介 / Keisuke Horiguchi'/>
              </div>

              <div className='w-[266px] md:w-[360px] lg:w-[399px] lg:flex lg:justify-between'>
                <ProfileLabel content='住所'/>
                <ProfileContent className="w-[280px]" content='東京都品川区西五反田2丁目26−5'/>
              </div>

              <div className='w-[266px] md:w-[360px] lg:w-[399px] lg:flex lg:justify-between'>
                <ProfileLabel content='資本金'/>
                <ProfileContent className="w-[280px]" content='1,000,000円'/>
              </div>

              <div className='w-[266px] md:w-[360px] lg:w-[399px] lg:flex lg:justify-between'>
                <ProfileLabel content='設立年月日'/>
                <ProfileContent className="w-[280px]" content='2023年12月25日'/>
              </div>

              <div className='w-[266px] md:w-[360px] lg:w-[399px] lg:flex lg:justify-between'>
                <ProfileLabel content='事業概要'/>
                <ProfileContent className="w-[280px]" content='ブロックチェーンおよびWeb3ゲームの企画・設計・システム開発・運営。付随または関連するコンサルティングおよびマーケティング業務、情報サービスの運営・提供。'/>
              </div>

              <div className='w-[266px] md:w-[360px] lg:w-[399px] lg:flex lg:justify-between'>
                <ProfileLabel content='主要取引銀行'/>
                <ProfileContent className="w-[280px]" content='三井住友銀行'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyProfile;
