import Text from "../atoms/Text";
import Image from "../atoms/Image";

const ServiceArticle = ({ title, content, number='01', src='https://source.unsplash.com/random/540x405', className='' }) => {
  return (
    <div className={`w-[330px] md:w-[640px] h-[604px] md:h-[706px] ${className}`}>
      <div className={`w-[330px] md:w-[640px] h-[604px] md:h-[706px] bg-[#EEF2F4] shadow-sm flex items-center justify-center ${className}`}>
        <div className='w-[270px] md:w-[540px] h-[503px] md:h-[566px] mx-auto'>
          <Image src={`${src}`} alt={`service${number}`} className='w-[270px] md:w-[540px] h-[203px] md:h-[405px] mb-[30px] md:mb-[50px]' />
          <div className='md:flex md:justify-between'>
            <div className='mb-[20px] md:mb-0 w-[270px] md:w-[111px] font-abel flex md:block justify-between'>
              <Text content='SERVICES /' className='text-[#0094FF] text-[16px]' />
              <Text content={`${number}`} className='text-[#0094FF] text-[62px] md:text-[72px] leading-[43px] md:leading-[72px]' />
            </div>
            <div className='w-[270px] md:w-[398px] h-[138px] md:h-[87px]'>
              <Text content={`${title}`} className='text-[16px] md:text-[17px] font-bold mb-[10px] md:mb-5' />
              <Text content={`${content}`} className='text-[14px]' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceArticle;
