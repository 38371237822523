import Text from "../atoms/Text";
import VerticalText from "../atoms/VerticalText";

const CompanyConcept = ({className=''}) => {
  return (
    <div className={`relative w-[324px] md:w-[485px] h-[253px] md:h-[296px] flex items-center justify-center ${className}`}>
      <VerticalText text='[ CONCEPT ]' className='absolute top-0 right-[0px] text-[#0094FF] text-[13px] md:text-[16px] font-abel'/>
      <div className='w-[244px] md:w-[365px] text-[13px] md:text-[16px] tracking-wide'>
        <Text content='Web2を知る電脳だからこそ、' className='leading-loose font-hiragino'/>
        <Text content='成し遂げられるWeb3戦略＆マーケティング事業' className='leading-loose font-hiragino'/>

        <br></br>
        <Text content='すべては、' className='leading-loose font-hiragino'/>
        <Text content='持続可能な電脳世界を創造するために' className='leading-loose font-hiragino'/>
        <Text content='私たちは挑戦を続けます' className='leading-loose font-hiragino'/>
      </div>
    </div>
  );
};

export default CompanyConcept;
