import Text from "../atoms/Text";

const CompanyMessage = ({className=''}) => {
  return (
    <div className={`w-[280px] md:w-[362px] h-[500px] md:h-[450px] flex items-center justify-center ${className}`}>
      <div className='w-[200px] md:w-[262px]'>
        <div className='text-[#0094FF] text-[13px] md:text-[16px] ml-[-60px] md:ml-[-100px] font-abel'>&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;MESSAGE</div>
        <Text content='代表からのメッセージ' className='mb-[10px] md:mb-5 font-bold mt-[20px] md:mt-5 text-[13px] md:text-[16px] leading-loose'/>
        <Text content='2014年の黎明期からおよそ10年間、ブロックチェーン業界を専門に様々な事業に取り組んできました。その中でも、web3ゲーム領域は日本の企業及びユーザーが暗号資産の歴史上初めて世界に対してイニシアチブを取れる可能性が高いこと、新たなカルチャーテックを作れると感じています。その挑戦をする企業の助けとなれるよう、ブロックチェーン業界での経験と日本のゲーム関連会社とのシナジーを創出し、日本を代表するweb3ゲームのマーケティングエージェンシー会社を作り、業界をリードします。' className='leading-loose text-[12px]'/>
      </div>
    </div>
  );
};

export default CompanyMessage;
