import React, { useEffect, useState } from 'react';
import Square from '../atoms/Square';

const RandomSquares = ({ count, color='white' }) => {
  const [squares, setSquares] = useState([]);

  useEffect(() => {
    const newSquares = [];
    for (let i = 0; i < count; i++) {
      const style = {
        zIndex: -2,
        position: 'absolute',
        backgroundColor: color,
        left: `${Math.random() * window.innerWidth}px`,
        top: `${Math.random() * window.innerHeight}px`
      };
      newSquares.push(<Square key={i} style={style} />);
    }
    setSquares(newSquares);
  }, [count, color]);

  return <div>{squares}</div>;
};

export default RandomSquares;
