const Label = ({ htmlFor, children }) => {
  return (
    <label htmlFor={htmlFor} className="block text-[12px]">
      {children}
      <span className="text-red-500">*</span>
    </label>
  );
};

export default Label;
