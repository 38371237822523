import Image from "../atoms/Image";

const ContactFormBackground1 = ({ className={} }) => {
  return (
    <>
      <div className='relative h-[0px]'>
        <Image src={process.env.PUBLIC_URL + '/contact_3.png'} className='absolute top-[20px] md:top-[220px] right-[400px] md:right-[480px] z-0 w-[13px] h-[327px] mx-auto lg:relative'/>
      </div>

      <div className='relative h-[0px]'>
        <Image src={process.env.PUBLIC_URL + '/contact_6.png'} className='absolute -top-[30px] md:-top-[10px] right-[50px] md:right-[350px] z-0 w-[13px] h-[643px] mx-auto lg:relative'/>
      </div>

      <div className='relative h-[300px]'>
        <Image src={process.env.PUBLIC_URL + '/contact_3.png'} className='absolute top-[70px] md:top-[70px] right-[170px] md:right-[150px] z-0 w-[13px] h-[327px] mx-auto lg:relative'/>
      </div>

      <div className='relative h-[0px]'>
        <Image src={process.env.PUBLIC_URL + '/contact_6.png'} className='absolute -top-[100px] md:-top-[80px] left-[20px] md:left-[480px] z-0 w-[13px] h-[643px] mx-auto lg:relative'/>
      </div>
    </>
  );
};

export default ContactFormBackground1;
