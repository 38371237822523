import Text from "../atoms/Text";
import ArrowLink from "../atoms/ArrowLink";

const CompanyAbout = ({className=''}) => {
  return (
    <div className={`w-[324px] md:w-[485px] h-[253px] md:h-[296px] flex items-center justify-center ${className}`}>
      <div className='w-[244px] md:w-[365px] h-[144px] md:h-[196px]'>
        <div className='text-[#0094FF] text-[13px] md:text-[16px] font-abel'>（ABOUT）</div>
        <Text content='ブロックチェーン事業の' className='text-[13px] md:text-[18px] leading-loose tracking-widest font-hiragino'/>
        <Text content='企画立案・トケノミクスから' className='text-[13px] md:text-[18px] leading-loose tracking-widest font-hiragino'/>
        <Text content='コンサルティング・マーケティング' className='leading-loose text-[13px] md:text-[18px] tracking-widest font-hiragino'/>
        <Text content='であなた全てをサポート' className='leading-loose text-[13px] md:text-[18px] tracking-widest mb-4 font-hiragino'/>
        <ArrowLink href='#services' content='OUR SERVICES→' className=''/>
      </div>
    </div>
  );
};

export default CompanyAbout;
