import React from 'react';

const VerticalText = ({ text, className }) => {
  return (
    <div className={`writing-mode-vertical-rl transform rotate-90 ${className}`}>
      {text}
    </div>
  );
}

export default VerticalText;
