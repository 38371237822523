import Image from "../atoms/Image";

const ServicesBackground = ({ className={} }) => {
  return (
    <>
      <div className='relative h-[500px] mt-[-100px] max-lg:overflow-hidden'>
        <Image src={process.env.PUBLIC_URL + '/SERVICES_1.png'} className='absolute max-w-fit w-[1372px] h-[368px] top-[150px] overflow-hidden lg:relative'/>
      </div>
      <div className='relative h-[500px] mt-[-368px] max-lg:overflow-hidden'>
        <Image src={process.env.PUBLIC_URL + '/SERVICES_2.png'} className='absolute max-w-fit w-[1372px] h-[368px] max-md:top-[0px] top-[100px] overflow-hidden lg:relative'/>
      </div>
    </>
  );
};

export default ServicesBackground;
